// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-expertise-jsx": () => import("./../../../src/pages/About/Expertise.jsx" /* webpackChunkName: "component---src-pages-about-expertise-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/About.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-about-registered-jsx": () => import("./../../../src/pages/About/Registered.jsx" /* webpackChunkName: "component---src-pages-about-registered-jsx" */),
  "component---src-pages-about-we-jsx": () => import("./../../../src/pages/About/WE.jsx" /* webpackChunkName: "component---src-pages-about-we-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/Contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-download-jsx": () => import("./../../../src/pages/Download.jsx" /* webpackChunkName: "component---src-pages-download-jsx" */),
  "component---src-pages-feedback-jsx": () => import("./../../../src/pages/Feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-gallery-achievements-jsx": () => import("./../../../src/pages/Gallery/Achievements.jsx" /* webpackChunkName: "component---src-pages-gallery-achievements-jsx" */),
  "component---src-pages-gallery-greetings-jsx": () => import("./../../../src/pages/Gallery/Greetings.jsx" /* webpackChunkName: "component---src-pages-gallery-greetings-jsx" */),
  "component---src-pages-gallery-infographics-jsx": () => import("./../../../src/pages/Gallery/Infographics.jsx" /* webpackChunkName: "component---src-pages-gallery-infographics-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/Gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-gallery-photos-a-jsx": () => import("./../../../src/pages/Gallery/Photos/a.jsx" /* webpackChunkName: "component---src-pages-gallery-photos-a-jsx" */),
  "component---src-pages-gallery-photos-b-jsx": () => import("./../../../src/pages/Gallery/Photos/b.jsx" /* webpackChunkName: "component---src-pages-gallery-photos-b-jsx" */),
  "component---src-pages-gallery-photos-c-jsx": () => import("./../../../src/pages/Gallery/Photos/c.jsx" /* webpackChunkName: "component---src-pages-gallery-photos-c-jsx" */),
  "component---src-pages-gallery-photos-d-jsx": () => import("./../../../src/pages/Gallery/Photos/d.jsx" /* webpackChunkName: "component---src-pages-gallery-photos-d-jsx" */),
  "component---src-pages-gallery-photos-e-jsx": () => import("./../../../src/pages/Gallery/Photos/e.jsx" /* webpackChunkName: "component---src-pages-gallery-photos-e-jsx" */),
  "component---src-pages-gallery-photos-f-jsx": () => import("./../../../src/pages/Gallery/Photos/f.jsx" /* webpackChunkName: "component---src-pages-gallery-photos-f-jsx" */),
  "component---src-pages-gallery-photos-g-jsx": () => import("./../../../src/pages/Gallery/Photos/g.jsx" /* webpackChunkName: "component---src-pages-gallery-photos-g-jsx" */),
  "component---src-pages-gallery-photos-h-jsx": () => import("./../../../src/pages/Gallery/Photos/h.jsx" /* webpackChunkName: "component---src-pages-gallery-photos-h-jsx" */),
  "component---src-pages-gallery-photos-jsx": () => import("./../../../src/pages/Gallery/Photos.jsx" /* webpackChunkName: "component---src-pages-gallery-photos-jsx" */),
  "component---src-pages-gallery-videos-jsx": () => import("./../../../src/pages/Gallery/Videos.jsx" /* webpackChunkName: "component---src-pages-gallery-videos-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pay-jsx": () => import("./../../../src/pages/Pay.jsx" /* webpackChunkName: "component---src-pages-pay-jsx" */),
  "component---src-pages-services-architecture-jsx": () => import("./../../../src/pages/Services/Architecture.jsx" /* webpackChunkName: "component---src-pages-services-architecture-jsx" */),
  "component---src-pages-services-branding-jsx": () => import("./../../../src/pages/Services/Branding.jsx" /* webpackChunkName: "component---src-pages-services-branding-jsx" */),
  "component---src-pages-services-coporate-identity-jsx": () => import("./../../../src/pages/Services/CoporateIdentity.jsx" /* webpackChunkName: "component---src-pages-services-coporate-identity-jsx" */),
  "component---src-pages-services-designing-jsx": () => import("./../../../src/pages/Services/Designing.jsx" /* webpackChunkName: "component---src-pages-services-designing-jsx" */),
  "component---src-pages-services-event-management-jsx": () => import("./../../../src/pages/Services/EventManagement.jsx" /* webpackChunkName: "component---src-pages-services-event-management-jsx" */),
  "component---src-pages-services-flim-jsx": () => import("./../../../src/pages/Services/Flim.jsx" /* webpackChunkName: "component---src-pages-services-flim-jsx" */),
  "component---src-pages-services-flim-post-production-jsx": () => import("./../../../src/pages/Services/Flim/PostProduction.jsx" /* webpackChunkName: "component---src-pages-services-flim-post-production-jsx" */),
  "component---src-pages-services-flim-pre-production-jsx": () => import("./../../../src/pages/Services/Flim/PreProduction.jsx" /* webpackChunkName: "component---src-pages-services-flim-pre-production-jsx" */),
  "component---src-pages-services-flim-production-jsx": () => import("./../../../src/pages/Services/Flim/Production.jsx" /* webpackChunkName: "component---src-pages-services-flim-production-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/Services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-training-finearts-jsx": () => import("./../../../src/pages/Training/Finearts.jsx" /* webpackChunkName: "component---src-pages-training-finearts-jsx" */),
  "component---src-pages-training-finearts-timings-jsx": () => import("./../../../src/pages/Training/Finearts/Timings.jsx" /* webpackChunkName: "component---src-pages-training-finearts-timings-jsx" */),
  "component---src-pages-training-it-jsx": () => import("./../../../src/pages/Training/IT.jsx" /* webpackChunkName: "component---src-pages-training-it-jsx" */),
  "component---src-pages-training-workshops-jsx": () => import("./../../../src/pages/Training/Workshops.jsx" /* webpackChunkName: "component---src-pages-training-workshops-jsx" */),
  "component---src-pages-trainings-jsx": () => import("./../../../src/pages/Trainings.jsx" /* webpackChunkName: "component---src-pages-trainings-jsx" */)
}

